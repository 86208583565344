import React from 'react'
import { HStack } from '@chakra-ui/react'
import BackButton from '../../../atoms/nav-controls/BackButton'
import RemoveButton from '../../../atoms/nav-controls/RemoveButton'
import DuplicateButton from '../../../atoms/nav-controls/DuplicateButton'

const ControlsImage = ({ handleBack, handleRemove, handleDuplicate }) => {
	return (
		<>
			<HStack spacing={['4%', '1rem']} justifyContent="flex-start">
				<BackButton onClick={handleBack} />
			</HStack>
			<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
				<DuplicateButton onClick={handleDuplicate} />
				<RemoveButton onClick={handleRemove} />
			</HStack>
		</>
	)
}

export default ControlsImage

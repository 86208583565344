import React from 'react'
import { HStack } from '@chakra-ui/react'
import BackButton from '../../../atoms/nav-controls/BackButton'

const ControlsUndefined = ({ handleBack }) => {
	return (
		<>
			<HStack spacing={['4%', '1rem']}>
				<BackButton onClick={handleBack} />
			</HStack>
		</>
	)
}

export default ControlsUndefined

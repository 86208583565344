import React, { useState, useEffect, memo } from 'react'
import { Container, Box, useDisclosure, Spinner, Text } from '@chakra-ui/react'
import Card from '../../atoms/Card'
import useEventListener from '@use-it/event-listener'
import MediaItemProvider, { computeMimeType, useMediaItemContext } from '../../../contexts/MediaItemProvider'
import loadable from '@loadable/component'
import ModalDeviceOrientation from '../../organisms/ModalDeviceOrientation'
import ModalUnsavedChanges from '../../organisms/ModalUnsavedChanges'
import { useEventContext } from '../../../contexts/EventProvider'
import { useAuthContext } from '../../../contexts/AuthProvider'
import NavBarControls from './controls'
import { FeatureItemFullWidth } from '../../molecules/FeatureItem'
import FooterBase from '../../molecules/FooterBase'
import useOrientation from '../../../hooks/useOrientation'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import { LowResIndicator } from './atoms/LowResIndicator'
import { isBrowser } from 'react-device-detect'
const ImageController = loadable(() => import(/* webpackPrefetch: true */ './src/Image'))
const VideoController = loadable(() => import(/* webpackPrefetch: true */ './src/Video'))
const TextCardController = loadable(() => import(/* webpackPrefetch: true */ './src/TextCard'))
const AudioController = loadable(() => import(/* webpackPrefetch: true */ './src/Audio'))
const UnknownController = loadable(() => import(/* webpackPrefetch: true */ './src/Unknown'))
const UndefinedController = loadable(() => import(/* webpackPrefetch: true */ './src/Undefined'))

const feature = {
	path: '/assets/images/graphics/vidday-preview-feature-qc',
	content: () => (
		<>
			<Text variant="title">Get a Quality Check.</Text>
			<Text pt=".25rem">Add a Quality Check at checkout, and a specialist will review and edit your media.</Text>
		</>
	),
	href: 'https://help.vidday.com/whats-the-quality-check/',
	isExternal: true,
}

const useOrientationModal = () => {
	const orientation = useOrientation()

	const { onClose, onOpen, isOpen } = useDisclosure()
	// const [showDeviceOrientationModal, setShowDeviceOrientationModal] = useState(false)

	useEffect(() => {
		if (orientation) {
			if (orientation == 'landscape' && !isBrowser) {
				// setShowDeviceOrientationModal(true)
				onOpen()
			} else {
				// setShowDeviceOrientationModal(false)
				onClose()
			}
		}
	}, [orientation, isBrowser])

	return { onClose, onOpen, isOpen }
}

const EditMedia = (props) => {
	const { isFetching } = useEventContext()
	const {
		item,
		type,
		textCardState,
		videoState,
		isEditMode,
		navigationType,
		handlers: { handlePrevious, handleNext },
		prevItem,
		nextItem,
	} = useMediaItemContext()

	const [key, setKey] = useState(new Date().getTime())

	const deviceModal = useOrientationModal()
	const { isAdmin } = useAuthContext()
	const unsavedModal = useDisclosure()

	const mediaState = videoState || textCardState
	const hasChanged = mediaState ? mediaState.hasChanged : false
	const handleSave = mediaState ? mediaState.handleSave : null
	const isFocused = textCardState ? textCardState.isFocused : false

	useScrollTopOnMount()

	// refresh component without saving
	const handleCancel = () => {
		setKey(new Date().getTime())
		if (type == 'textcard') {
			textCardState.setHasChanged(false)
		}
	}

	const handleKeyDown = (e) => {
		var RIGHT_ARROW_KEY = 'ArrowRight' // next
		var LEFT_ARROW_KEY = 'ArrowLeft' // prev
		var SAVE = 'KeyS'

		// Prevent default for hot keys
		if (e.code == RIGHT_ARROW_KEY || e.code == LEFT_ARROW_KEY) {
			e.preventDefault()
		}

		if (e.code == RIGHT_ARROW_KEY) {
			!isEditMode && handleNext(mediaState, unsavedModal)
		}
		if (e.code == LEFT_ARROW_KEY) {
			!isEditMode && handlePrevious(mediaState, unsavedModal)
		}
		if (e.code == SAVE && !isFocused) {
			if (mediaState && hasChanged) {
				if (isAdmin) {
					handleSave && handleSave()
					unsavedModal.onClose()
				} else {
					// unsavedModal.onOpen()
				}
			}
		}
	}

	useEventListener('keydown', handleKeyDown)

	const MediaPrefetch = ({ media }) => {
		let type = computeMimeType(media?.mimeType)
		let item = <></>
		switch (type) {
			case 'image':
			case 'video':
				item = (
					<link
						id={media?.id}
						rel="preload" // prefetch not supported fully in safari, but preload is see: https://caniuse.com/?search=preload
						as={type}
						type={media.mimeType}
						href={media?.signedLocationUrl}
						crossOrigin="anonymous"
					/>
				)
				break
			default:
				break
		}
		return item
	}

	return (
		<>
			<NavBarControls handleCancel={handleCancel} /*key={key}*/ />
			<Container maxW="container.xl" pt={['1rem', '1rem']} pb="1rem" variant="main" pr="1rem" pl="1rem">
				{!isFetching && (
					<>
						<Card px={['1rem', '1rem']} py="1rem" alignItems="center" variant="main" mb="2rem">
							<LowResIndicator />
							{type == 'image' && <ImageController />}
							{type == 'video' && (
								<VideoController key={key} handleCancel={handleCancel} unsavedModal={unsavedModal} />
							)}
							{type == 'textcard' && (
								<TextCardController key={key} handleCancel={handleCancel} unsavedModal={unsavedModal} />
							)}
							{type == 'audio' && <AudioController key={key} />}
							{type == 'unknown' && <UnknownController key={key} />}
							{(type == undefined || !item) && <UndefinedController key={key} />}
						</Card>
						{mediaState && (
							<ModalUnsavedChanges
								onClose={unsavedModal.onClose}
								isOpen={unsavedModal.isOpen}
								onSave={mediaState.handleSave}
								onCancel={handleCancel}
								navigationType={navigationType}
								onNext={handleNext}
								onPrevious={handlePrevious}
							/>
						)}
						<Box mx={['1rem', '0']}>
							<FeatureItemFullWidth item={feature} mr="0" ml="0" />
						</Box>
						<FooterBase />
					</>
				)}
				{isFetching && <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />}
			</Container>
			<ModalDeviceOrientation isOpen={deviceModal.isOpen} onClose={deviceModal.onClose} />
			<MediaPrefetch media={prevItem} />
			<MediaPrefetch media={nextItem} />
		</>
	)
}

const EditMediaRoot = memo(() => {
	const { event } = useEventContext()
	return (
		<>
			<MediaItemProvider event={event}>
				<EditMedia />
			</MediaItemProvider>
		</>
	)
})

export default EditMediaRoot

import React from 'react'
import { Button } from '@chakra-ui/react'
import { CancelRounded, DeleteRounded } from '../Icon'

const RemoveButton = ({ onClick, text }) => {
	return (
		<Button variant="control" color="#CC0033" onClick={onClick}>
			<CancelRounded />
			{text || 'Remove'}
		</Button>
	)
}

export default RemoveButton

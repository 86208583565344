import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'

const RemoveFiles = (props) => {
	const { onClose, onRemove, fileCount } = props

	const onSubmit = (data) => {
		onRemove()
		onClose()
	}

	return (
		<>
			<ModalBody>
				<VStack textAlign="center" spacing="1rem">
					<Image
						maxW="100px"
						src="/assets/images/graphics/modal-remove-item.png"
						srcSet="/assets/images/graphics/modal-remove-item@2x.png 2x, /assets/images/graphics/modal-remove-item.png 1x"
						alt="Remove item from gallery"
					/>
					<Heading as="h3" size="lg" variant="hero">
						Remove media?
					</Heading>
					<Text>
						You are about to{' '}
						<strong>
							set aside {fileCount} item{fileCount > 1 ? 's' : ''}{' '}
						</strong>
						&mdash; you can find them in the drawer below.
					</Text>
				</VStack>
			</ModalBody>
			<ModalFooter>
				<HStack w="full" spacing="1rem" justifyContent="center">
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
					<Button colorScheme="red" onClick={onSubmit}>
						Yes, Remove
					</Button>
				</HStack>
			</ModalFooter>
		</>
	)
}

const ModalGalleryRemoveItems = (props) => {
	const { onClose, isOpen } = props
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<RemoveFiles {...props} />
			</ModalContent>
		</Modal>
	)
}
export default ModalGalleryRemoveItems

import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalUnsavedChanges = ({ onClose, isOpen, onSave, onCancel, onNext, onPrevious, navigationType }) => {
	let navigationHandler = null
	if (navigationType == 'next') {
		navigationHandler = onNext
	}
	if (navigationType == 'previous') {
		navigationHandler = onPrevious
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Warning" boxSize="80px" />
						<Heading size="lg" variant="hero">
							Unsaved changes
						</Heading>
						<Text>Do you want to save your changes before continuing?</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							variant="ghost"
							onClick={() => {
								onCancel()
								navigationHandler && navigationHandler()
								onClose()
							}}>
							Don't Save
						</Button>
						<Button
							onClick={() => {
								onSave()
								navigationHandler && navigationHandler()
								onClose()
							}}>
							Save
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalUnsavedChanges

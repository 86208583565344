import React from 'react'
import {
	Modal,
	VStack,
	Heading,
	Image,
	Text,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
} from '@chakra-ui/react'

const ModalDeviceOrientation = ({ onClose, isOpen }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							boxSize="80px"
							src={'/assets/images/icons/vertical-phone.svg'}
							alt="Device Orientation"
						/>
						<Heading size="lg" variant="hero">
							Vertical is Best
						</Heading>
						<Text>We suggest keeping your phone vertical for a better experience.</Text>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalDeviceOrientation

// A really common need is to get the current size of the browser window.
// This hook returns an object containing the window's width and height. If executed server-side (no window object) the value of width and height will be undefined.

import { useState, useEffect } from 'react'

function useOrientation() {
	// Initialize state with undefined width/height so server and client renders match
	const [orientation, setOrientation] = useState()

	useEffect(() => {
		function handleOrientationDetect() {
			// Handler to call on window resize
			if (window.matchMedia('(orientation: portrait)').matches) {
				setOrientation('portrait')
			}

			if (window.matchMedia('(orientation: landscape)').matches) {
				setOrientation('landscape')
			}
		}

		// Add event listener
		window.addEventListener('resize', handleOrientationDetect)

		// On load run once
		handleOrientationDetect()

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleOrientationDetect)
	}, []) // Empty array ensures that effect is only run on mount

	return orientation
}

export default useOrientation

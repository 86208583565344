import React from 'react'
import { HStack } from '@chakra-ui/react'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'
import CancelButton from '../../../atoms/nav-controls/CancelButton'
import BackButton from '../../../atoms/nav-controls/BackButton'
import SaveButton from '../../../atoms/nav-controls/SaveButton'
import RemoveButton from '../../../atoms/nav-controls/RemoveButton'
import DuplicateButton from '../../../atoms/nav-controls/DuplicateButton'

const ControlsTextCard = ({ handleCancel, handleBack, handleRemove, handleDuplicate }) => {
	const { textCardState } = useMediaItemContext()

	const { hasChanged, handleSave } = textCardState

	return (
		<>
			<HStack spacing={['4%', '1rem']}>
				{hasChanged ? <CancelButton onClick={handleCancel} /> : <BackButton onClick={handleBack} />}
			</HStack>
			<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
				<DuplicateButton onClick={handleDuplicate} />
				<SaveButton disabled={!hasChanged} onClick={handleSave} />
				<RemoveButton onClick={handleRemove} />
			</HStack>
		</>
	)
}

export default ControlsTextCard

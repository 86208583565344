import React from 'react'
import { Button } from '@chakra-ui/react'
import { ContentCopyRounded } from '../Icon'

const DuplicateButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<ContentCopyRounded />
			Duplicate
		</Button>
	)
}

export default DuplicateButton

import React from 'react'
import { HStack, Text, Link } from '@chakra-ui/react'
import { computeMimeType, useMediaItemContext } from '../../../../contexts/MediaItemProvider'
import { WarningRounded } from '../../../atoms/Icon'

export const LowResIndicator = ({ mediaModel, ...rest }) => {
	const mediaContext = useMediaItemContext()
	const m = mediaModel ? { ...mediaModel, type: computeMimeType(mediaModel.mimeType) } : mediaContext
	const ignore = ['Gif', 'Unsplash']
	const mimeType = m?.item?.mimeType
	const displayInfo = m && m.hasLowResolution && !ignore.includes(m.medium)

	const title = {
		info: `Low-resolution ${m?.type} detected.`,
		suggestionPartOne: ` We suggest uploading a higher quality version
        if possible, or choose `,
		suggestionPartTwo: ` at checkout for AI upscaling.`,
		suggestionMimeTypeVideo: ` We suggest uploading a higher quality version if possible.`,
	}
	if (displayInfo) {
		return (
			<HStack p="0.5rem" mb="1rem" mt="1rem" bgColor="#ffaf2e1a" {...rest}>
				<WarningRounded fontSize="1rem" color="#ffaf2f" />
				<Text fontSize="0.795rem" color="#47596a">
					<strong color="gray.900">{title.info}</strong>
					{mimeType?.startsWith('video') ? (
						<>{title.suggestionMimeTypeVideo}</>
					) : (
						<>
							{title.suggestionPartOne}
							<Link
								href="https://help.vidday.com/whats-the-quality-check/"
								isExternal
								textDecoration="underline">
								<strong>Quality Check</strong>
							</Link>
							{title.suggestionPartTwo}
						</>
					)}
				</Text>
			</HStack>
		)
	}
	return <></>
}

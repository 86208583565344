import React from 'react'
import NavBar from '../../../atoms/NavBar'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'
import ControlsDefault from './Default'
import ControlsImage from './Image'
import ControlsVideo from './Video'
import ControlsTextCard from './TextCard'
import ControlsUndefined from './Undefined'
import ModalGalleryRemoveItems from '../../../organisms/ModalGalleryRemoveItems'
import { useDisclosure } from '@chakra-ui/react'

const NavBarControls = ({ handleCancel }) => {
	/**
	 * Handler to delete a media.
	 * Currently, the media to be deleted is the media currently fetched here.
	 */
	// const [deleteModalOpen, setDeleteModelOpen] = useState(false)
	const removeItem = useDisclosure()

	const {
		type,
		handlers: { handleRemove, handleBack, handleDuplicate },
	} = useMediaItemContext()

	// const handleDeleteModalTrigger = () => setDeleteModelOpen(true)

	return (
		<>
			<NavBar position="fixed">
				{type == 'image' && (
					<ControlsImage
						handleBack={handleBack}
						handleRemove={removeItem.onOpen}
						handleDuplicate={handleDuplicate}
					/>
				)}

				{(type == 'video' || type == 'audio') && (
					<ControlsVideo
						handleCancel={handleCancel}
						handleBack={handleBack}
						handleRemove={removeItem.onOpen}
						handleDuplicate={handleDuplicate}
					/>
				)}

				{type == 'textcard' && (
					<ControlsTextCard
						handleCancel={handleCancel}
						handleBack={handleBack}
						handleRemove={removeItem.onOpen}
						handleDuplicate={handleDuplicate}
					/>
				)}

				{type == 'unknown' && <ControlsDefault handleBack={handleBack} />}

				{type == undefined && <ControlsUndefined handleBack={handleBack} />}
			</NavBar>

			<ModalGalleryRemoveItems
				isOpen={removeItem.isOpen}
				fileCount={1}
				onClose={removeItem.onClose}
				onRemove={handleRemove}
			/>
		</>
	)
}

export default NavBarControls
